<script>
import Swal from 'sweetalert2';
import VIcon from '@/components/Icon.vue'
import CardPassForm from '@/components/CardPassForm.vue'
import VBlockCard from './BlockCard.vue';
import VRequestNewCard from './RequestNewCard.vue';
import VCard from './Card.vue';
import VTextbox from './Textbox.vue';

export default {
  components: {
    VBlockCard,
    VRequestNewCard,
    VCard,
    VIcon,
    VTextbox,
    CardPassForm,
  },
  props: {
    showCanceledCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cardNumber: null,
      cardNumberCOP: null,
      showCard: false,
      showDigitalCard: false,
      showPhisicalCardCOP: false,
      document: '',
      codeSended: '',
      userData: null,
      loadingSpinner: false,
      showPass: false,
    }
  },
  computed: {
    cards() {
      return this.$store.state.cards;
    },
    user() {
      return this.$store.state.user;
    },
    userPassword() {
      return this.$store.state.userPassword;
    },
    showAddCard() {
      return this.$route.name === 'my-cards'
    }
  },
  mounted() {
    this.getCards();
    this.userData = JSON.parse(localStorage.getItem('user'));
  },
  methods: {
    getCards() {
      if (localStorage.getItem('c') != null || !this.showAddCard) {
        try {
          this.$store.commit('setCards', JSON.parse(localStorage.getItem('c')).cards);
          this.showCard = JSON.parse(localStorage.getItem('c')).hasNewCard;
          this.showDigitalCard = JSON.parse(localStorage.getItem('c')).hasNewDigitalCard;
          this.showPhisicalCardCOP = JSON.parse(localStorage.getItem('c')).hasNewPhisicalCardCOP
        } catch (e) {
          this.getCardsFromAPI()
        }
      } else {
        this.getCardsFromAPI()
      }
    },
    getCardsFromAPI() {
      this.$http.get('/cards/listarCartoesOnlyPay')
        .then((res) => {
          localStorage.setItem('c', res.request.response);
          this.$store.commit('setCards', JSON.parse(res.request.response).cards);
          this.showCard = JSON.parse(res.request.response).hasNewCard
          this.showDigitalCard = JSON.parse(res.request.response).hasNewDigitalCard
          this.showPhisicalCardCOP = JSON.parse(res.request.response).hasNewPhisicalCardCOP
        })
        .catch(() => {})
    },
    showAlert() {
      Swal(
        'OnlyPay Informa',
        '<p style="text-align: left; font-size: 16px; margin-top:50px;margin-bottom:50px;">Estamos com instabilidade em nosso sistema.<br>Nosso time está trabalhando para resolução o mais breve possível. <br>Pedimos desculpas pelo transtorno. <br><br>Pedimos que direcione sua solicitações e dúvidas para faleconosco@onlypay.com.br.<p>',
        'info'
      )
    },
    addCard() {
      if (this.$store.state.formLoading) return false;

      if (this.cardNumber) {
        this.$store.commit('formLoading', true);
        this.loadingSpinner = true;

        this.$http.post('/cards/unlockNewCard', {
          numeroCartao: this.cardNumber
        })
          .then(data => {
            localStorage.removeItem('c');
            if (!data.message) {
              this.$store.commit('message', {
                type: 'info',
                text: 'Cartão adicionado com sucesso'
              });

              this.loadingSpinner = false;
              this.showPass = true;

              this.cardNumber = '';
              // this.getCardsFromAPI();
            }
          }, err => {
            this.$store.commit('message', {
              type: 'error',
              text: err
            });
            this.loadingSpinner = false;
          });
      } else {
        this.$store.commit('message', {
          type: 'error',
          text: 'Digite o número do cartão para adicioná-lo'
        });
      }
    },
    addPhisicalCardCOP() {
      if (this.$store.state.formLoading) return false;

      if (this.cardNumberCOP) {
        this.$store.commit('formLoading', true);
        this.loadingSpinner = true;

        if (this.user.cpf) {
          this.document = this.user.cpf
        } else {
          this.document = this.user.cnpj
        }

        this.$http.post('/cards/unlockNewCardCOP', {
          cardNumber: this.cardNumberCOP,
          document: this.document
        })
          .then(res => {
            localStorage.removeItem('c');
            Swal.fire({
              title: 'Sucesso',
              text: res.data.message,
              type: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
              allowOutsideClick: false
            }).then((result) => {
              if (result) {
                this.cardNumberCOP = '';
                this.document = '';
                this.loadingSpinner = false;
                window.location.reload();
              }
            });
          }).catch(err => {
            Swal.fire({
              title: 'Oops!',
              text: err.message || 'Não foi possível concluir a operação nesse momento, tente novamente mais tarde',
              icon: 'error',
              confirmButtonText: 'Voltar'
            });
            this.loadingSpinner = false;
            return false;
          })
      } else {
        this.$store.commit('message', {
          type: 'error',
          text: 'Digite o número do cartão para adicioná-lo'
        });
      }
    },
    unlockDigitalCard() {
      if (this.$store.state.formLoading) return false;
      if (this.codeSended) {
        this.$store.commit('formLoading', true);
        this.loadingSpinner = true;
        if (this.user.cpf) {
          this.document = this.user.cpf
        } else {
          this.document = this.user.cnpj
        }
        const codeSended = this.codeSended.replace(/\.|-/g, '');
        this.$http.post('users/create-virtualcard-phoneprocess-loggedArea', {
          codeSended,
          document: this.document
        })
          .then((res) => {
            localStorage.removeItem('c');
            Swal.fire({
              title: 'Sucesso',
              text: res.data.message,
              type: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
              allowOutsideClick: false
            }).then((result) => {
              if (result) {
                this.codeSended = '';
                this.loadingSpinner = false;
                window.location.reload();
              }
            });
          }).catch(err => {
            Swal.fire({
              title: 'Oops!',
              text: err.message || 'Não foi possível concluir a operação nesse momento, tente novamente mais tarde',
              icon: 'error',
              confirmButtonText: 'Voltar'
            });
            this.loadingSpinner = false;
            return false;
          })
      }
    },
    async addDigitalCard() {
      let userData = null;
      if (this.userData.tipo === 'PF') {
        userData = {
          codeSended: this.codeSended.replace(/\.|-/g, ''),
          name: this.userData.nome,
          motherName: this.userData.nomeMae,
          document: this.document,
          birthDate: this.userData.dataNascimento,
          address: {
            idAddressType: 1,
            street: this.userData.enderecos.logradouro.substring(0, 39),
            number: this.userData.enderecos.numero,
            zipCode: this.userData.enderecos.cep,
            city: this.userData.enderecos.cidade,
            neighborhood: this.userData.enderecos.bairro.substring(0, 39),
            federativeUnit: this.userData.enderecos.uf,
            country: 'Brasil',
            complement: (this.userData.enderecos.complemento == null ? this.userData.enderecos.complemento : this.userData.enderecos.numero),
            referencePoint: ''
          },
          phone: {
            idPhoneType: 18,
            areaCode: this.userData.telefones.ddd,
            number: this.userData.telefones.numeroTelefone,
          },
          password: this.userPassword,
          email: this.userData.email,
        }
      } else { // type PJ
        userData = {
          codeSended: this.codeSended.replace(/\.|-/g, ''),
          tipo: this.$store.getters.getDocumentType,
          nome: this.userData.nome,
          cnpj: this.userData.cnpj,
          dataNascimento: this.userData.birthDate,
          pessoaDetalhes: {
            email: this.userData.email,
            nomeEmpresa: this.userData.nome
          },
          endereco: {
            cep: this.userData.enderecos.cep,
            logradouro: this.userData.enderecos.logradouro.substring(0, 39),
            numero: this.userData.enderecos.numero,
            complemento: (this.userData.enderecos.complemento == null ? this.userData.enderecos.complemento : this.userData.enderecos.numero),
            pontoReferencia: '',
            bairro: this.userData.enderecos.bairro.substring(0, 39),
            cidade: this.userData.enderecos.cidade,
            uf: this.userData.enderecos.uf,
            pais: 'Brasil'
          },
          telefone: {
            idTipoTelefone: 1,
            ddd: this.userData.telefones.ddd,
            telefone: this.userData.telefones.numeroTelefone,
            ramal: ''
          },
          password: this.userPassword,
        }
      }
      await this.$http.post(`/users/create-virtualcard-phone-process?type=${this.user.tipo}`, userData)
        // eslint-disable-next-line no-unused-vars
        .then(async () => {
          this.codeSended = '';
          Swal.fire({
            title: 'Sucesso',
            text: 'O seu cartão foi desbloqueado com sucesso.',
            type: 'success',
            showCancelButton: false,
            confirmButtonText: 'Entendi',
            allowOutsideClick: false
          });

          this.getCardsFromAPI();
        })
        .catch((err) => {
          Swal.fire({
            title: 'Oops!',
            text: err.message || 'Não foi possível concluir a operação nesse momento, tente novamente mais tarde',
            icon: 'error',
            confirmButtonText: 'Voltar'
          });
          return false;
        });
    },
  },
};
</script>

<template>
  <div class="cards">
    <div class="cards__list">
      <!-- Desbloqueio de Cartão Físico solicitado pelo Console Antigo -->
      <div class="card" v-if="showCard && showAddCard">
        <div class="">
          <div class="card__figure">
            <v-textbox
              v-model="cardNumber"
              style="color: #fff;"
              class="card"
              label="Insira o número do cartão físico"
              name="cardNumber"
              placeholder="•••• •••• •••• ••••"
              required
              mask="#### #### #### ####"
              type="text" />
          </div>
            <div class="card__actions">
              <a
                @click="addCard"
                class="card__actions__action" :class="{'disable': cardNumber == null || cardNumber.length !== 19}">
                <v-icon v-if="!loadingSpinner" class="card__actions__action__icon" name="add"/>
                <span v-if="!loadingSpinner" class="card__actions__action__text addBtn__actions__action__loading">Adicionar</span>
                <v-icon v-if="loadingSpinner" style=" width: 19px; height: 19px;" class="refresh-icon__figure__toggle  rotating-refresh-icon" name="refresh-icon" />
              </a>
            </div>
        </div>
      </div>

      <!-- Desbloqueio de Cartão Físico solicitado pelo Console Novo -->
      <!-- <div class="card" v-if="showPhisicalCardCOP && showAddCard">
        <div class="">
          <div class="card__figure">
            <v-textbox
              v-model="cardNumberCOP"
              style="color: #fff;"
              class="card"
              label="Insira o número do cartão físico"
              name="cardNumber"
              placeholder="•••• •••• •••• ••••"
              required
              mask="#### #### #### ####"
              type="text" />
          </div>
            <div class="card__actions">
              <a
                @click="addPhisicalCardCOP"
                class="card__actions__action" :class="{'disable': cardNumberCOP == null || cardNumberCOP.length !== 19}">
                <v-icon v-if="!loadingSpinner" class="card__actions__action__icon" name="add"/>
                <span v-if="!loadingSpinner" class="card__actions__action__text addBtn__actions__action__loading">Adicionar</span>
                <v-icon v-if="loadingSpinner" style=" width: 19px; height: 19px;" class="refresh-icon__figure__toggle  rotating-refresh-icon" name="refresh-icon" />
              </a>
            </div>
        </div>
      </div> -->

      <!-- Desbloqueio de Cartão Digital -->
      <div class="card" v-if="showDigitalCard && showAddCard">
        <div class="">
          <div class="card__figure">
            <v-textbox
              v-model="codeSended"
              style="color: #fff;"
              class="card"
              label="Código do cartão digital"
              name="codeSended"
              placeholder="••••-••••-••••"
              required
              mask="XXXX-XXXX-XXXX"
              type="text" />
          </div>
            <div class="card__actions">
              <a
                @click="unlockDigitalCard"
                class="card__actions__action" :class="{'disable': codeSended == null || codeSended.length !== 14}">
                <v-icon v-if="!loadingSpinner" class="card__actions__action__icon" name="add"/>
                <span v-if="!loadingSpinner" class="card__actions__action__text addBtn__actions__action__loading">Adicionar</span>
                <v-icon v-if="loadingSpinner" style=" width: 19px; height: 19px;" class="refresh-icon__figure__toggle  rotating-refresh-icon" name="refresh-icon" />
              </a>
            </div>
        </div>
      </div>

      <!-- Cartões -->
      <v-card :showCanceledCard="showCanceledCard" v-for="(card, index) in cards" v-bind="$attrs" :card="card" :key="index"
        v-on="$listeners" />
    </div>
    <v-block-card />
    <v-request-new-card/>
    <transition v-if="showPass" name="fade">
      <CardPassForm
        @close="showPass = false" :callGetCardsFromAPI="getCardsFromAPI" />
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
.cards {
  &__list {
    @media (min-width: 576px) {
      display: grid;
      grid-column-gap: 1.5rem;
      grid-template-columns: 1fr 1fr;
    }

    .card {
      color: var(--color-white);
      border-radius: .5rem;
      margin-bottom: 1.5rem;
      position: relative;
      transition: all 250ms;

      &__figure {
        cursor: pointer;
        background: linear-gradient(135deg, #7cd07e, #8bbad4);
        border-radius: .5rem;
        color: var(--color-white);
        cursor: default;
        display: block;
        overflow: hidden;
        padding: 13% 1.5rem 1.5rem;
        position: relative;
        transition: all 250ms;
        width: 100%;

        &:hover {
          text-decoration: none;
        }
      }

      &__actions {
        background-color: var(--color-white);
        border: 1px solid var(--color-grey-light);
        border-radius: 0 0 .5rem .5rem;
        border-top: none;
        color: var(--color-black);
        display: flex;
        margin-top: -.5rem;
        flex-direction: row;
        padding: 1rem 0 .5rem;

        &__action {
          flex-grow: 1;
          padding: .5rem 1.5rem;
          text-align: center;
          width: 100%;
          color: var(--color-green-dark);

          &__icon {
            margin-right: .5rem;
            size: 1.25rem;
          }

          &__text {
            font-size: .625rem;
            font-weight: bold;
            line-height: 1.25rem;
            text-transform: uppercase;
            vertical-align: middle;
            transition: all 0.2s;
          }
        }
      }
    }
  }

  .warning__pass {
    position: absolute;
    z-index: 1;
    background-color: #cacacc;
    top: 5px;
    left: 125px;
    margin-right: 5px;
    color: #000;
    border: 1px solid #00a749;
    font-size: 11px;
    padding: 5px;
    font-weight: 600;
    border-radius: 5px;
    text-align: center;
  }

  .disable{
    pointer-events: none;
    cursor: default;
    color: var(--color-grey-medium);
  }

  .refresh-icon {
    &__figure {
      &__toggle {
        margin: -.125rem 0 .3rem .50rem;
        size: 1.5rem;
        transform: rotate(-180deg);
      }
      @media screen and (max-width: 735px) and (min-width:575px){
          margin-top: 20px;
      }
      @media screen and (max-width: 371px) {
        margin-top: 30px;
      }
    }
  }

  .rotating-refresh-icon {
    animation: rotating-refresh-icon 1s infinite linear;
  }

  @keyframes rotating-refresh-icon {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .rotating {
    animation: rotation 1s infinite linear;
  }
  @keyframes shadow-pulse
  {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 167, 73, 0.2);
    }
    80% {
      box-shadow: 0 0 0 10px rgba(0, 167, 73, 0);
    }
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}

</style>
